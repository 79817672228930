import React,{createContext, useState,useEffect} from 'react';
import lodash from 'lodash'
import Auth from './Auth';
import moment from 'moment'

const initialAuth = {
    language:"fr",
    list:[],
    movies:[],
    count:0,
    user_id:null,
    filter:"",
    search:'',
    searchCatalog:"",
    setSearch: () => {},
    setSearchCatalog: () => {},
    setFilter: () => {},
    setLanguage: () => {},
    setMovies: () => {},
    isInMyList: () => {},
    getList: () => {},
    addMovie: () => {},
    deleteMovie: () => {},
    addSerie: () => {},
    deleteSerie: () => {},
    trierPar: () => {},
    getViewCounter:() =>{},
    addViewCounter: () => {},

}

export const GlobalContext = createContext(initialAuth)

export const GlobalProvider = ({children}) => {

    const [language,setLanguage] = useState("fr-FR")
    const [list,setList] = useState([])
    const [movies,setMovies] = useState([])
    const [user_id,setUserId] = useState(1)
    const [loading,setLoading] = useState(true)
    const [count,setCount] = useState(0)
    const [filter,setFilter] = useState("")
    const [searchCatalog,setSearchCatalog] = useState("")
    const [search,setSearch] = useState("")
    const [max, setMax] = useState(0)


    useEffect(() => {

        if(localStorage.getItem("search")){
            setSearch(localStorage.getItem("search"))
        }

        if(localStorage.getItem("language")){
            setLanguage(localStorage.getItem("language"))
        }else{
            localStorage.setItem("language",'fr-FR')
        }

        if(localStorage.getItem("trierPar")){
            setFilter(localStorage.getItem("trierPar"))
        }else{
            localStorage.setItem("trierPar",'date')
        }

        setLoading(false)
    }, []);


    useEffect(() => {
            getAllMovies()
    }, []);

    const getAllMovies = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/movie/getAll`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({user_id:user_id})
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            console.log("All films : ", json.films)
            setList(json.films)
            return json.films
        }).catch((error)=>{
            console.log(error)
        })
        let mov = []
        lodash.forEach(res,(f,i)=>{
            let tps_mov = JSON.parse(f.infos)
            tps_mov.media_type = f.media_type
            tps_mov.id_movie = f.id_movie
            tps_mov.key = i
            tps_mov.dateAjout = f.date
            tps_mov.view_counter = f.view_counter
            tps_mov.last_view = f.last_view
            mov.push(tps_mov)
        })
        trierPar('date',mov)
        if(res){
            //getList(res)
        }
    }

    const getList = async (arr) => {
        let tab = []
        let count = 0
        setCount((prev)=>prev-prev)
        for await (let m of arr) {
            let res 
                if(m.media_type == "movie"){
                    res = await fetch(`https://api.themoviedb.org/3/movie/${m.id_movie}?api_key=5c47e1d0868537d5542fcede64c202db&language=${language}`,{
                        method:'GET',
                        headers:{
                            "Content-Type":"application/json",
                        }
                    })
                    .then((response)=>{
                        return response.json()
                    })
                    .then((json)=>{
                        return json
                    }).catch((error)=>{
                        console.log(error)
                    })
                    res.media_type = m.media_type
                    res.key=count
                    tab.push(res)
                    setCount((prev)=>prev+1)
                }else{
                    res = await fetch(`https://api.themoviedb.org/3/tv/${m.id_movie}?api_key=5c47e1d0868537d5542fcede64c202db&language=${language}`,{
                        method:'GET',
                        headers:{
                            "Content-Type":"application/json",
                        }
                    })
                    .then((response)=>{
                        return response.json()
                    })
                    .then((json)=>{
                        return json
                    }).catch((error)=>{
                        console.log(error)
                    })
                    res.media_type = m.media_type
                    res.key=count
                    tab.push(res)
                    setCount((prev)=>prev+1)
                }
                count++
        }
        setMax(count)
        trierPar('date',tab)
    }

    const isInMyList = (id) => {
        let find = lodash.find(movies,(m)=>{
            return m.id_movie == id
        })
        return find ? true : false
    }

    //Ajouter un film en BDD et dans la liste
    const addMovie = (movie) => {
        fetch(`${process.env.REACT_APP_API_URL}/movie/add`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({
                movie:{
                    id_movie:movie.id,
                    media_type:"movie",
                    infos:JSON.stringify(movie),
                    date:moment().format("DD-MM-YYYY HH:mm:ss")
                },
                user_id:user_id
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.Status == "ok"){
                let tps_movies = lodash.cloneDeep(movies)
                movie.media_type = "movie"
                movie.key= max+1
                movie.id_movie = json.item.id_movie
                movie.dateAjout = json.item.date
                movie.view_counter = 1
                setMax((prev)=>prev+1)
                tps_movies.push(movie)
                trierPar(localStorage.getItem("trierPar"),tps_movies)
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    //Ajouter une serie en BDD et dans la liste
    const addSerie = (serie) => {
        fetch(`${process.env.REACT_APP_API_URL}/movie/add`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({
                movie:{
                    id_movie: serie.id,
                    media_type: "tv",
                    infos:JSON.stringify(serie),
                    date:moment().format("DD-MM-YYYY HH:mm:ss")
                },
                user_id:user_id
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.Status == "ok"){
                let tps_movies = lodash.cloneDeep(movies)
                serie.media_type = "tv"
                serie.key= max+1
                serie.id_movie = json.item.id_movie
                serie.dateAjout = json.item.date
                setMax((prev)=>prev+1)
                tps_movies.push(serie)
                trierPar(localStorage.getItem("trierPar"),tps_movies)
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    //Supprimer un film de la BDD et de la liste
    const deleteMovie = (movie) => {
        fetch(`${process.env.REACT_APP_API_URL}/movie/delete`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({
                user_id:user_id,
                id:movie.id
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then(()=>{
            //update List
            let tps_list = lodash.cloneDeep(list)
            lodash.remove(tps_list,(item) => item.id_movie === movie.id)
            setList(tps_list)
            //Update Movies
            let tps_movies = lodash.cloneDeep(movies)
            lodash.remove(tps_movies,(item) => item.id === movie.id)
            trierPar(localStorage.getItem("trierPar"),tps_movies)
        }).catch((error)=>{
            console.log(error)
        })
    }

    //Supprimer une serie de la BDD et de la liste
    const deleteSerie = (serie) => {
        fetch(`${process.env.REACT_APP_API_URL}/movie/delete`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({
                user_id:user_id,
                id:serie.id
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then(()=>{
            //update List
            let tps_list = lodash.cloneDeep(list)
            lodash.remove(tps_list,(item) => item.id_movie === serie.id)
            setList(tps_list)
            //Update Movies
            let tps_movies = lodash.cloneDeep(movies)
            lodash.remove(tps_movies,(item) => item.id === serie.id)
            trierPar(localStorage.getItem("trierPar"),tps_movies)
        }).catch((error)=>{
            console.log(error)
        })
    }

    //Récupère le nombre de fois qu'a été vu un film ou une série
    const getViewCounter = (ID) => {
        let find = lodash.find(movies,(m)=>{
            return m.id_movie == ID
        })
        return find.view_counter
    }
    
    //Ajoute une vue à un film ou une série
    const addViewCounter = (ID) => {
        let tps_movies = lodash.cloneDeep(movies)
        lodash.find(tps_movies,(m)=>{
            if(m.id_movie == ID){
                m.view_counter += 1
                //TODO -- Ajouter en BDD
                fetch(`${process.env.REACT_APP_API_URL}/movie/increaseViewCounter`,{
                    method:'POST',
                    headers:{
                        "Content-Type":"application/json",
                    },
                    body:JSON.stringify({
                        user_id:user_id,
                        id:m.id_movie,
                        view_counter:m.view_counter,
                        last_view:moment().format("DD-MM-YYYY HH:mm:ss")
                    })
                })
                .then((response)=>{
                    return response.json()
                })
                .then(()=>{
                    console.log('Update')
                }).catch((error)=>{
                    console.log(error)
                })
            }
        })
        setMovies(tps_movies)
    }

    //Trier les films
    const tri = (a,b) =>{
        if ((a.media_type == "movie" ? a.title : a.name ) < (b.media_type == "movie" ? b.title : b.name)) return -1;
        else if ((a.media_type == "movie" ? a.title : a.name ) == (b.media_type == "movie" ? b.title : b.name)) return 0;
        else return 1;
    }

    const trierPar = (e,f)=>{
        let tab = []
        let films = lodash.cloneDeep(f)
        setFilter(e)
        switch (e) {
            case "date":
                tab = films.sort((a,b) =>{
                    return(
                        moment(b.dateAjout,"DD-MM-YYYY HH:mm:ss").diff(moment(a.dateAjout,"DD-MM-YYYY HH:mm:ss"))
                    )
                })
                break;
            case "alphabetique":
                tab = films.sort(tri)
                break;
            case "dateDecroissante":
                //Du plus récent
                tab = films.sort((a,b) =>{
                    return(
                        new moment(b.media_type == "movie" ? b.release_date : b.first_air_date).format('YYYYMMDD') - new moment(a.media_type == "movie" ? a.release_date : a.first_air_date).format('YYYYMMDD')
                    )
                })
                break;
            case "dateCroissante":
                //Du plus ancien
                tab = films.sort((a,b) => {
                    return(
                        new moment(a.media_type == "movie" ? a.release_date : a.first_air_date).format('YYYYMMDD') - new moment(b.media_type == "movie" ? b.release_date : b.first_air_date).format('YYYYMMDD')
                    )
                })
                break;
            case "noteCroissante":
                tab = films.sort((a,b) => b.vote_average - a.vote_average)
                break;
            case "noteDecroissante":
                tab = films.sort((a,b) => a.vote_average - b.vote_average)
                break;

        }
        setMovies(tab)
    }

    return (
        <GlobalContext.Provider value={{
            language,
            user_id,
            movies,
            count,
            filter,
            search,
            setSearch,
            setFilter,
            setLanguage,
            setMovies,
            isInMyList,
            getList,
            addMovie,
            deleteMovie,
            addSerie,
            deleteSerie,
            trierPar,
            getViewCounter,
            addViewCounter,
            searchCatalog,
            setSearchCatalog
        }}>
            {loading ?
            null
        : children
        }
        </GlobalContext.Provider>
    )
}
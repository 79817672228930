import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import BottomTab from './BottomTab'
import { Link, useLocation } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/outline'
import { GlobalContext } from './GlobalContext'

const Serie = (props) => {

    const [ serie, setSerie ] = useState(null)
    const [ detailSeason, setDetailSeason ] = useState({
        index: null,
        visible: false,
    })
    const { language, isInMyList, addSerie, deleteSerie, getViewCounter, addViewCounter } = useContext(GlobalContext)

    useEffect(() => {
        getInfos()
    }, [])

    const getInfos = () => {
        fetch(`https://api.themoviedb.org/3/tv/${id}?api_key=5c47e1d0868537d5542fcede64c202db&language=${language}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((json) => {
                console.log(json)
                setSerie(json)
            }).catch((error) => {
            console.log(error)
        })
    }

    let { id } = useParams()
    const location = useLocation()
    if (!serie) {
        return (
            <div>Chargement</div>
        )
    }
    return (
        <>
            <BottomTab/>

            <Link to={{
                pathname: location.state.backPath,
                state: { search: location.state.search, trierPar: location.state.trierPar },
            }}>
                <div className="grid grid-cols-5 mt-4 ml-4">
                    <span className="bg-back text-primary p-0.5 rounded-md text-center">Retour</span>
                </div>
            </Link>
            <div className="p-4 flex flex-col mb-20">
                <span className="font-bold text-2xl ">{serie.name ? serie.name : null}</span>
                <span className="font-medium text-lg mb-2">{serie.tagline ? serie.tagline : null}</span>
                <div className="grid grid-cols-2 lg:grid-cols-5 gap-4">
                    <div className=" group-hover:opacity-75 block w-full aspect-w-10 aspect-h-15 rounded-t-lg ">
                        {serie.poster_path ?
                            <img src={"https://image.tmdb.org/t/p/w1280" + serie.poster_path} alt="" className=" rounded-lg pointer-events-none "/>
                            :
                            <div className="border-t border-l border-r border-dashed border-secondary rounded-t-lg"></div>
                        }
                    </div>
                    <div className="flex flex-col mt-2">
                        <span><u className="font-semibold">Saisons</u>: {serie.number_of_seasons}</span>
                        <span><u className="font-semibold">Episodes</u>: {serie.number_of_episodes}</span>
                        <span><u className="font-semibold">Note</u>: {serie.vote_average} / 10</span>
                        <div>
                            {isInMyList(serie.id) ?
                                <>
                                <span onClick={() => deleteSerie(serie)} className="cursor-pointer mt-6 inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-red-300 text-red-800">
                                    Supprimer de ma liste
                                </span>
                                    <span onClick={() => addViewCounter(serie.id)}
                                          className="cursor-pointer mt-6 inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-blue-300 text-blue-800">
                                    Revu ( Vu {getViewCounter(serie.id)} fois )
                                </span>
                                </>
                                :
                                <>
                                    <span onClick={() => addSerie(serie)}
                                          className="cursor-pointer mt-6 inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-green-300 text-green-800">
                                    Ajouter à ma liste des séries vu
                                </span>
                                   
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-span-2">
                        {serie.genres.map((g, i) => {
                            return (
                                <>
                                        <span className="mx-1 my-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-300 text-yellow-800">
                                            {g.name}
                                        </span>
                                </>
                            )
                        })}
                    </div>
                </div>
                <span className="font-bold text-2xl mt-4">Saisons :</span>
                <div className="grid grid-cols-3 lg:grid-cols-5 gap-2 mt-2">
                    {serie.seasons.map((s, i) => {
                        return (
                            <>
                                <div onClick={() => setDetailSeason({ ...detailSeason, visible: true, index: i })}>
                                    <div className=" group-hover:opacity-75 block w-full aspect-w-10 aspect-h-15 rounded-t-lg ">
                                        {s.poster_path ?
                                            <img src={"https://image.tmdb.org/t/p/w1280" + s.poster_path} alt="" className=" rounded-t-lg pointer-events-none "/>
                                            :
                                            <div className="border-t border-l border-r border-dashed border-secondary rounded-t-lg"></div>
                                        }
                                    </div>
                                    <div className="bg-white shadow-lg px-2 py-1 rounded-b-lg pointer-events-none border-t">
                                        <p className="text-sm text-center font-medium text-gray-900 truncate ">{s.name}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>

                {detailSeason.visible ?
                    <>
                        <div className="bg-primary border-secondary border fixed inset-x-0 bottom-16 z-50 rounded-t-3xl flex flex-col h-96 overflow-scroll">
                            <div className="grid grid-cols-3 font-bold border-b border-secondary py-2 sticky top-0 bg-primary">
                                <span className="col-start-2 flex justify-center items-center">{serie.seasons[detailSeason.index].name}</span>
                                <div onClick={() => setDetailSeason({ ...detailSeason, visible: false })} className="flex justify-end items-center pr-6">
                                    <XCircleIcon className={"inline-block py-1 h-8 w-8"}/>
                                </div>
                            </div>
                            <div className="p-2 h-full">
                                {serie.seasons[detailSeason.index].overview ? serie.seasons[detailSeason.index].overview : "Il n'y a pas de résumé pour cette saison."}
                            </div>
                        </div>
                    </>
                    : null}
                <span className="font-bold text-2xl mt-4">Résumé :</span>
                <div className="mt-2 p-2 bg-primaryLight bg-opacity-50 rounded-md">
                    {serie.overview}
                </div>
            </div>


        </>
    )
}
export default Serie

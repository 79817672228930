import React from 'react';
import {Router,Route,Switch,Redirect} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { GlobalProvider } from './GlobalContext';

import List from './List';
import Search from './Search';
import Movie from './Movie';
import Serie from './Serie';

const customHistory = createBrowserHistory()

//Routing de l'application web
const Routing = () => (
    <GlobalProvider>
        <Router history={customHistory} >
            <Switch>
                <Route exact component={List} path="/movies" />
                <Route exact component={Search} path="/search" />
                <Route exact component={Movie} path="/movie/:id" />
                <Route exact component={Serie} path="/serie/:id" />
                <Route exact path='*'><Redirect to="/movies" /></Route>
            </Switch>
        </Router>
    </GlobalProvider>
)
export default Routing
